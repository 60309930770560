.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container input {
    height: 34px;
    width: 100%;
    border: .1px solid #d8d8d8;
    padding-left: 12px;
    border-radius: 0px !important;
}

.react-datepicker__input-container input:focus {
    outline: none;
    border: 2px solid #ed1b2e;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
    padding: 5px;
}

.declaration_form_datepickeer {
    width: 25%;
}